<template>
  <div class="statistics">
    <el-card shadow="never" :body-style="{
      minHeight: 'calc(100vh - 153px)'
    }">
      <div class="chooseTime">
        <span>时间:</span>
        <el-date-picker
            v-model="valueDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="handleChange">
        </el-date-picker>
      </div>
      <div id="main" style="height: 400px"></div>
      <div v-if="sumNum <= 0" class="no-data-box">
        <nodata></nodata>
      </div>
    </el-card>
  </div>
</template>

<script>
import moment from 'moment'
import {Card,DatePicker} from "element-ui";
import {getDownloadNum} from "../../../api/accountCenter";
import * as echarts from 'echarts/core';
import { GridComponent,LegendComponent,TooltipComponent, } from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import Nodata from "../../../components/noData";

export default {
  name: "Statistics",
  components:{
    Nodata,
    [Card.name]:Card,
    [DatePicker.name]: DatePicker,
  },
  data() {
    return {
      valueDate:'',
      sumNum: 1,
      queryJson:{
        startTime:null,
        endTime: null,
      },
      myChart: null,
      option: {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['下载数']
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name:'下载数',
            data: [],
            type: 'line',
            itemStyle: {normal: {label: {show: true}}},
          }
        ]
      },
    }
  },
  mounted() {
    this.initData()
    echarts.use([GridComponent, LineChart, CanvasRenderer, UniversalTransition,LegendComponent,TooltipComponent]);
    let chartDom = document.getElementById('main');
    this.myChart = echarts.init(chartDom);
    this.option && this.myChart.setOption(this.option);
  },
  methods: {
    initData() {
      getDownloadNum(this.queryJson).then(res => {
        if(res.code === 200) {
          this.sumNum = res.data.sumNum
          if(res.data.list.length > 0) {
            let xAxisData = []
            let seriesData = []
            res.data.list.map(item => {
              xAxisData.push(item.date)
              seriesData.push(item.num)
              this.myChart.setOption({
                xAxis: {
                  data: xAxisData
                },
                series: [
                  {
                    data: seriesData
                  }
                ]
              })
            })
          } else {
            this.myChart.setOption({
              xAxis: {
                data: []
              },
              series: [
                {
                  data: []
                }
              ]
            })
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },

    handleChange(value){
      if(value) {
        this.queryJson.startTime = moment(value[0]).format('YYYY-MM-DD')
        this.queryJson.endTime = moment(value[1]).format('YYYY-MM-DD')
      } else {
        this.queryJson.startTime = null
        this.queryJson.endTime = null
      }
      this.initData()
    },

  },
}
</script>

<style scoped lang="scss">
.statistics {
  position: relative;
  background-color: #ffffff;
  .chooseTime {
    margin-bottom: 24px;
    span {
      margin-right: 8px;
    }
  }
  .no-data-box {
    position: absolute;
    top: 86px;
    width: calc(100% - 40px);
    height: 400px;
    background-color: #ffffff;
  }
}

</style>
